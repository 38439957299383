<template>
  <b-overlay :show="showLoading">
    <b-row>

      <b-col cols="12">
        <b-card class="">
          <h4>بنر صفحه درباره ما</h4>

          <b-row>
            <b-media class="my-2 col-12">
              <template>
                <b-avatar
                    class="cursor-pointer w-100"
                    ref="previewEl"
                    :src="base64ImageSrc"
                    size="400px"
                    rounded
                    @click.native="$refs.refInputEl1.click()"
                >
                </b-avatar>
              </template>
            </b-media>

          </b-row>

          <b-button
              variant="warning"
              @click="$refs.refInputEl1.click()"
              class="mt-2 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
          >
            <input
                ref="refInputEl1"
                type="file"
                class="d-none"
                @input="makeBase64Pic($event,'base64ImageSrc')"
            >
            <span class="d-none d-sm-inline">ویرایش</span>
            <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
            />
          </b-button>

          <b-button
              variant="primary"
              class="mt-2 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="setPic(1)"
          >
            ثبت
          </b-button>

        </b-card>
      </b-col>

      <b-col cols="12">
        <b-card v-for="item in pageData">

          <b-form-group
              :label="item.title"
              label-for="blog-content"
              class="mb-2"
          >
            <b-form-textarea
                id="blog-content"
                rows="4"
                v-model="item.content"
            ></b-form-textarea>
          </b-form-group>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateSection(item)"
          >
            ثبت
          </b-button>

        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BOverlay,BFormTextarea,BFormGroup
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import {PostCreateRequest} from "@/libs/Api/Post";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {StaticPageGetByTypeRequest, StaticPageUpdateRequest} from "@/libs/Api/StaticPage";
import {AccountGetSliderRequest, AccountSetSliderRequest} from "@/libs/Api/Account";
export default {
  name: "aboutUs",
  title: 'ویرایش درباره ما - پنل ادمین مکس ',
  data(){
    return{
      pageData:null,
      base64ImageSrc: null,
      pic:null,
      showLoading:false,
    }
  },
  async created(){
    await this.getData();
    await this.getPics();
  },
  methods:{
    async getPics() {
      let _this = this;
      _this.showOverlay = true;

      let accountGetSliderRequest = new AccountGetSliderRequest(_this)
      await accountGetSliderRequest.fetch(function (content) {
        content.forEach(item => {
          if (item.priority === 4) {
            _this.base64ImageSrc = 'https://api.maxterms.com/media/gallery/slider/'+item.pictureUrl
          }
        })
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e, index) {
      const _this = this;
      let file = e.target.files[0];
      _this[index] = URL.createObjectURL(file);
      _this.pic = await _this.readAsDataURL(file);
    },
    async setPic() {
      let _this = this;
      _this.overlay = true;

      let data = {
          base64: _this.pic,
          priority: 4
        }

      let accountSetSliderRequest = new AccountSetSliderRequest(_this);
      accountSetSliderRequest.setParams(data);
      await accountSetSliderRequest.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        console.log(error)
      })
    },
    async getData(){
      let _this = this;
      _this.showLoading = true;

      let type = 2;

      let staticPageGetByTypeRequest = new StaticPageGetByTypeRequest(_this);
      staticPageGetByTypeRequest.setType(type);
      await staticPageGetByTypeRequest.fetch(function (content){
        _this.showLoading = false;
        _this.pageData = content;
      },function (error){
        _this.showLoading = false;
        console.log(error)
      })
    },
    async updateSection(param){
      let _this = this;

      _this.showLoading=true;

      let staticPageUpdateRequest = new StaticPageUpdateRequest(_this);
      staticPageUpdateRequest.setParams(param);
      await staticPageUpdateRequest.fetch(function (content){
        _this.showLoading=false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.getData();
      },function (error){
        _this.showLoading=false;
        console.log(error)
      })
    },
  },
  components:{
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    quillEditor,
    BFormTextarea,
    BFormGroup
  },
}
</script>

<style scoped>

</style>